import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { useIntl } from 'react-intl'

import Container from '@objects/container'
import Stage from '@components/stage'
import NewsList from '@components/newsList'

export const frontmatter = {
  breadcrumbs: [
    {
      label: 'navigation.press',
      link: 'press.path',
    },
  ],
}

function PressNotesPage({ data, pageContext }) {
  const intl = useIntl()

  const articles = data.contentfulArticleList?.articles
  const heroId = data.contentfulArticleList?.articleHero?.id
  const heroTitle = data.contentfulArticleList?.articleHero?.title
  const heroSlug = data.contentfulArticleList?.articleHero?.slug
  const heroPdf = data.contentfulArticleList?.articleHero?.pdf
  const heroCopy =
    data.contentfulArticleList?.articleHero?.teaserCopy?.childMarkdownRemark
      ?.html

  const mappedArticles = articles
    ?.map((article) => {
      return {
        id: article.id,
        headline: article.title,
        copy: article.teaserCopy?.childMarkdownRemark?.html,
        pdf: article.pdf,
        slug: `${intl.formatMessage({
          id: 'press.article.path',
        })}${article.slug}/`,
      }
    })
    .filter((article) => article.id !== heroId)
  const allArticles = [
    {
      id: heroId,
      headline: heroTitle,
      slug: heroSlug,
      copy: heroCopy,
      pdf: heroPdf,
    },
  ].concat(mappedArticles)

  return (
    <>
      <Stage
        alignTop={true}
        type={'generic'}
        title={pageContext.page?.title}
        ariaLabel={pageContext.page?.title}
      />
      {!!allArticles && !!articles && (
        <Container width="sm" ariaLabel="arialabel.presse">
          <NewsList items={allArticles} itemHeadlineLevel={2} />
        </Container>
      )}
    </>
  )
}

PressNotesPage.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.object,
  modules: PropTypes.array,
}

export default PressNotesPage

export const pageQuery = graphql`
  query PressReleasePageQuery($locale: String!) {
    contentfulArticleList(
      node_locale: { eq: $locale }
      identifier: { eq: "pressemitteilungen" }
    ) {
      articles {
        id
        slug
        title
        teaserCopy {
          childMarkdownRemark {
            html
          }
        }
        pdf {
          title
          file {
            url
            details {
              size
            }
          }
        }
      }
      articleHero {
        id
        title
        slug
        teaserCopy {
          childMarkdownRemark {
            html
          }
        }
        pdf {
          title
          file {
            url
            details {
              size
            }
          }
        }
      }
    }
  }
`
