import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { Link } from 'gatsby'

import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'

import Headline from '@objects/headline'
import Button from '@objects/button'
import Copy from '@objects/copy'
import Card from '@objects/card'

const useStyles = makeStyles((theme) => ({
  headline: {
    marginBottom: theme.spacing(14),
  },
  cardHeadline: {
    ...theme.typography.h5,
  },
  copy: {
    marginBottom: theme.spacing(2),
    ...theme.typography.teaser,
  },
  button: {
    marginTop: theme.spacing(5),
  },
  buttonwrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
}))

function NewsList({ className, headline, itemHeadlineLevel, items, button }) {
  const classes = useStyles()
  const intl = useIntl()

  function renderNews() {
    return items.map((item, i) => (
      <Card key={`item${i}`} bar="grey">
        <Link to={item.slug} tabIndex="-1">
          <Headline level={itemHeadlineLevel} className={classes.cardHeadline}>
            {item.headline}
          </Headline>
        </Link>
        <Copy className={classes.copy} html={item.copy} />
        <div className={classes.buttonwrapper}>
          <Button to={item.slug} type="text" color="red">
            {intl.formatMessage({ id: 'press.read.more' })}
          </Button>
          {!!item.pdf && (
            <Button type="text" icon="Download" href={item.pdf.file?.url}>
              PDF ({(item.pdf.file?.details.size / (1024 * 1024)).toFixed(2)}MB)
            </Button>
          )}
        </div>
      </Card>
    ))
  }

  return (
    <>
      {headline && <Headline className={classes.headline}>{headline}</Headline>}
      {renderNews()}
      {button && (
        <Box textAlign="center">
          <Button className={classes.button} to={button.link}>
            {button.label}
          </Button>
        </Box>
      )}
    </>
  )
}

NewsList.propTypes = {
  className: PropTypes.string,
  headline: PropTypes.string,
  itemHeadlineLevel: PropTypes.number,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string.isRequired,
      headline: PropTypes.string.isRequired,
      copy: PropTypes.string,
      pdf: PropTypes.shape({
        title: PropTypes.string,
        file: PropTypes.shape({
          url: PropTypes.string,
          details: PropTypes.shape({
            size: PropTypes.string,
          }),
        }),
      }),
    })
  ).isRequired,
  button: PropTypes.shape({
    link: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
}

export default NewsList
